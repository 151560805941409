import React, {FC} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import {StaticImage} from 'gatsby-plugin-image';

import TeamCard from 'components/TeamCard';

import Girafa1 from 'images/icons/girafa_1.svg';
import Girafa2 from 'images/icons/girafa_2.svg';
import Girafa4 from 'images/icons/girafa_4.svg';

import 'styles/index.scss';

const Team: FC = ({}) => {
  // const lari = getImage(data.lari);
  const data = useStaticQuery(graphql`
    query MyQuery {
      lari: file(relativePath: {eq: "lari-2022.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
        }
      }
      thais: file(relativePath: {eq: "thais-2022.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
        }
      }
      gi: file(relativePath: {eq: "giovana-2022.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
        }
      }
      daiane: file(relativePath: {eq: "daiane-2023.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
        }
      }
      julia: file(relativePath: {eq: "julia-cut.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
        }
      }
    }
  `);

  return (
    <div className="section-container background-2" id="equipe">
      <div className="content-container">
        <div className="equipe-container">
          <p className="section-name">Nossa Equipe</p>
          <section>
            <h2 className="m-b-32">IDEALIZADORA DA FONÍ</h2>
            <TeamCard
              photo={data.lari.childImageSharp.gatsbyImageData}
              name="Larissa de Medeiros Botecchia"
              crfa="2-20045"
              graduation={[
                'Fonoaudióloga (USP - Universidade de São Paulo)',
                'Mestre em Saúde da Comunicação Humana (Santa Casa de São Paulo)',
              ]}
              acting={[
                'Linguagem oral e escrita',
                'Fala, incluindo apraxia de fala na infância e gagueira',
                'Dedica-se ao atendimento de crianças e adolescentes, com foco na primeira infância',
              ]}
            />
          </section>

          <div className="separator">
            <Girafa1 />
          </div>

          <section>
            <h2 className="m-t-64 m-b-32">PROFISSIONAIS COLABORADORAS</h2>
            <TeamCard
              photo={data.thais.childImageSharp.gatsbyImageData}
              name="Thaís Soares da Silva"
              crfa="2-20044"
              graduation={[
                'Fonoaudióloga (USP - Universidade de São Paulo)',
                'Especialista em fonoaudiologia hospitalar com enfoque em Disfagia Infantil (Hospital das Clínicas - USP)',
              ]}
              acting={[
                'Motricidade orofacial',
                'Linguagem oral',
                'Fala',
                'Dedica-se ao atendimento de crianças e adolescentes, em especial na área de alterações do neurodesenvolvimento',
              ]}
            />
            <TeamCard
              photo={data.gi.childImageSharp.gatsbyImageData}
              name="Giovana Chaves Oliveira"
              crfa="2-22006"
              graduation={[
                'Fonoaudióloga (USP - Universidade de São Paulo)',
                'Mestranda em Ciências da Reabilitação (USP - Universidade de São Paulo)',
              ]}
              acting={[
                'Fala',
                'Linguagem oral',
                'Motricidade Orofacial',
                'Dedica-se ao atendimento de crianças e adolescentes, especialmente na área de Transtorno dos Sons da Fala',
              ]}
            />
            {/* <TeamCard */}
            {/*   photo={data.daiane.childImageSharp.gatsbyImageData} */}
            {/*   name="Daiane Alves Martins" */}
            {/*   crfa="2-21773" */}
            {/*   graduation={[ */}
            {/*     'Fonoaudióloga (USP - Universidade de São Paulo)', */}
            {/*     'Aprimoranda em Apraxia de Fala na Infância (Abrapraxia)', */}
            {/*   ]} */}
            {/*   acting={[ */}
            {/*     'Linguagem oral', */}
            {/*     'Fala', */}
            {/*     'Dedica-se ao atendimento de crianças especialmente nas áreas de fala e linguagem, com experiência no atendimento de crianças com diagnóstico de Transtorno do Espectro Autista (TEA)', */}
            {/*   ]} */}
            {/* /> */}

            <TeamCard
              photo={data.julia.childImageSharp.gatsbyImageData}
              name="Júlia Calheiros Santos "
              crfa="2-23185"
              graduation={[
                'Fonoaudióloga (USP - Universidade de São Paulo)',
                'Especializanda em Linguagem, com ênfase nos Distúrbios de Aprendizagem e na Atuação Educacional (FAMOSP/CEFAC)',
              ]}
              acting={['Linguagem oral e escrita', 'Fala']}
            />
          </section>

          <div className="separator">
            <Girafa2 />
          </div>

          <section>
            <h2 className="m-t-64 m-b-32">PROFISSIONAIS PARCEIRAS</h2>
            <p className="p-l-24 m-b-64">
              Acreditamos que o trabalho em equipe é fundamental para o sucesso
              da intervenção! Para isso, contamos com o apoio de profissionais
              parceiras (pediatras, neuropediatras, otorrinolaringologistas,
              fisioterapeutas, terapeutas ocupacionais, psicólogas,
              neuropsicólogas e psicopedagogas) com quem dividimos pacientes,
              estudamos casos e compartilhamos o trabalho multidisciplinar.
            </p>
            <div className="team-image-container">
              <StaticImage
                src="../images/parceiris.jpg"
                alt="Foto de perfil"
                className="static-image"
                layout="fullWidth"
              />
            </div>
          </section>

          <div className="separator m-t-64">
            <Girafa4 />
          </div>
        </div>
      </div>
    </div>
  );
};

// );

export default Team;
