import clsx from 'clsx';
import {AnimatePresence, AnimateSharedLayout, motion} from 'framer-motion';
import {IGatsbyImageData, GatsbyImage} from 'gatsby-plugin-image';
import React, {FC, useState} from 'react';

import styles from 'styles/Gallery.module.scss';

const variants = {
  visible: {opacity: 1},
  hidden: {opacity: 0},
};

interface Props {
  className?: string;
  id?: string;
  images: Record<string, IGatsbyImageData>;
}

const Gallery: FC<Props> = ({className, id, images}) => {
  const [selectedId, setId] = useState('');

  const {main, ...rest} = images;

  const renderImage = (id: string, img: IGatsbyImageData) => (
    <motion.div
      className={clsx(styles.div, id === 'main' && styles.main)}
      layoutId={id}
      key={id}
      onClick={() => setId(id)}
    >
      <GatsbyImage className={styles.image} image={img} alt="Galeria Foni" />
    </motion.div>
  );

  return (
    <div id={id} className={clsx(styles.gallery, className)}>
      <div className="content-container">
        <p className="section-name">Nosso Espaço</p>
        {/* <h2 className="m-b-32">Galeria de Imagens</h2> */}
        <AnimateSharedLayout type="crossfade">
          <div className={styles.images}>
            {/* {renderImage('main', main)} */}

            <div className={styles.grid}>
              {Object.entries(images).map(
                ([id, img]) => img && renderImage(id, img),
              )}
            </div>
          </div>

          <AnimatePresence>
            {images[selectedId] && (
              <>
                <motion.div
                  variants={variants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  onClick={() => setId('')}
                  className={styles.overlay}
                >
                  <motion.div layoutId={selectedId} className={styles.modal}>
                    <GatsbyImage
                      className={styles.image}
                      image={images[selectedId]}
                      alt="Galeria Foni"
                    />
                  </motion.div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    </div>
  );
};

export default Gallery;
