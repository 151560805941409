import clsx from 'clsx';
import {motion} from 'framer-motion';
import React, {FC} from 'react';
import {useInView} from 'react-intersection-observer';

interface Props {
  threshold?: number;
  triggerOnce?: boolean;
  className?: string;
}

const AnimateIn: FC<Props> = ({
  threshold = 0.1,
  triggerOnce = true,
  className,
  ...rest
}) => {
  const {ref, inView} = useInView({threshold, triggerOnce});
  return (
    <motion.div
      ref={ref}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 100}}
      transition={{type: 'tween', duration: 0.7}}
      className={clsx(className)}
      {...rest}
    />
  );
};

export default AnimateIn;
