import React, {FC} from 'react';

import ButtonLink from 'components/ButtonLink';

import Girafa from 'images/icons/girafa.svg';

import 'styles/index.scss';

interface Props {
  className?: string;
  id?: string;
}
const GetToKnowUs: FC<Props> = ({className, id}) => {
  return (
    <div className={className} id={id}>
      <div className="content-container">
        <div className="about-container invert gettoknowus">
          <Girafa className="girafa-container" />
          <div className="text-area">
            <h1 className="m-b-12">Venha nos conhecer!</h1>
            <p>
              <span>Aqui os atendimentos</span> seguem planejamentos{' '}
              <span>personalizados</span> e atendemos considerando cada criança
              de forma integral,{' '}
              <span>acolhendo e orientando com foco em metas e objetivos.</span>
            </p>
            <p className="m-b-40">Muito obrigada por escolher a FONÍ!</p>
            <ButtonLink
              href="https://api.whatsapp.com/send/?phone=5511932849257&text=Ol%C3%A1%2C+eu+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0"
              variation
            >
              Agendar consulta
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetToKnowUs;
