import React, {FC, ReactNode} from 'react';

import GreenCheck from 'images/icons/greenCheckIcon.svg';

import styles from 'styles/FonoCard.module.scss';
import AnimateIn from './AnimateIn';

interface Props {
  title: string;
  items: string[];
  icon?: ReactNode;
}

const FonoCard: FC<Props> = ({title, items, icon}) => (
  <AnimateIn className={styles.container}>
    {!!icon && icon}
    <h3 className={styles.title}>{title}</h3>
    <div className={styles['items-container']}>
      {items.map(text => (
        <div key={text} className={styles.line}>
          <GreenCheck />
          <p className="m-t-6 m-b-6">{text}</p>
        </div>
      ))}
    </div>
  </AnimateIn>
);

export default FonoCard;
