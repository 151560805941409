import React, {FC} from 'react';
import {
  getImage,
  IGatsbyImageData,
  ImageDataLike,
  StaticImage,
} from 'gatsby-plugin-image';
import {graphql, PageProps} from 'gatsby';
import {EventEmitter} from 'events';

import Layout from 'components/layout';
import Seo from 'components/seo';
import Team from 'components/Team';
import FonoCard from 'components/FonoCard';
import ImageFlip from 'components/ImageFlip';
import ButtonLink from 'components/ButtonLink';

import Girafa from 'images/icons/girafa.svg';
import Fala from 'images/icons/fala.svg';
import MotricidadeOrofacial from 'images/icons/motricidadeOro.svg';
import LinguagemEscrita from 'images/icons/linguagemEscrita.svg';
import LinguagemOral from 'images/icons/linguagemOral.svg';

import 'styles/index.scss';
import Gallery from 'components/Gallery';
import GetToKnowUs from 'components/GetToKnowUs';
import Footer from 'components/Footer';

interface PageData {
  somos1: ImageDataLike;
  somos2: ImageDataLike;
  comunicacao1: ImageDataLike;
  comunicacao2: ImageDataLike;
  galeria: ImageDataLike;
  galeria1: ImageDataLike;
  galeria2: ImageDataLike;
  galeria3: ImageDataLike;
  galeria4: ImageDataLike;
  galeria5: ImageDataLike;
  galeria6: ImageDataLike;
  galeria7: ImageDataLike;
}

// Importe o módulo 'events'

// Aumente o limite de ouvintes globalmente para o valor desejado (por exemplo, 20)
EventEmitter.defaultMaxListeners = 20;

const IndexPage: FC<PageProps<PageData>> = ({data}) => {
  const carousel1 = [getImage(data?.somos1), getImage(data?.somos2)];

  const carousel2 = [
    getImage(data?.comunicacao1),
    getImage(data?.comunicacao2),
  ];

  const gallery = {
    image0: getImage(data.galeria),
    image1: getImage(data.galeria1),
    image2: getImage(data.galeria2),
    image3: getImage(data.galeria3),
    image4: getImage(data.galeria4),
    image5: getImage(data.galeria5),
    image6: getImage(data.galeria6),
    image7: getImage(data.galeria7),
  } as Record<string, IGatsbyImageData>;

  return (
    <Layout>
      <Seo title="" />
      <div className="landing-container">
        <Girafa />

        <div className="background">
          <StaticImage
            src="../images/capa2.png"
            alt="Foto de perfil"
            className="landing-image-container"
            layout="fullWidth"
          />
        </div>
        <div className="section-container">
          <div className="half-container">
            <div className="text m-t-24">
              <h1>Um espaço de acolhimento e cuidado com a infância</h1>
              <h4 className="m-t-16 m-b-40">Boas-vindas à FONÍ !</h4>
              <ButtonLink
                href="https://api.whatsapp.com/send/?phone=5511932849257&text=Ol%C3%A1%2C+eu+gostaria+de+marcar+uma+consulta&type=phone_number&app_absent=0"
                variation
              >
                Agendar consulta
              </ButtonLink>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container" id="sobre">
        <div className="content-container">
          <div className="about-container">
            <ImageFlip className="image-container" images={carousel1} />
            <div className="text-area with-image">
              <p className="section-name">Sobre nós</p>
              <h1 className="m-b-0">Somos FONÍ</h1>
              <h4 className="m-t-0">
                Clínica Infantil com foco em fonoaudiologia
              </h4>
              <p>
                Da união entre conhecimento em saúde e encanto por crianças,
                nasce a FONÍ - um espaço de cuidado e acolhimento para a
                infância. <br /> <br />
                Aqui, cuidamos para potencializar o desenvolvimento de crianças
                com dificuldades na comunicação. <br /> <br />
                Por meio de atendimentos éticos e afetivos, visamos melhorar a
                qualidade de vida des pacientes e suas famílias. <br /> <br />
                Boas-vindas!{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container background-2" id="oquefazemos">
        <div className="content-container">
          <div className="about-container invert">
            <div className="text-area with-image">
              <p className="section-name">Atuação</p>
              <h1 className="m-b-8">Trabalhamos com comunicação!</h1>
              <p>
                <span>
                  Realizamos avaliação, acompanhamento e terapia fonoaudiológica
                </span>{' '}
                para crianças.
                <br /> <br />
                Acreditamos que o trabalho em equipe é fundamental para o
                sucesso da intervenção!
                <br /> <br />
                Por isso realizamos <span>orientações</span> aos responsáveis e
                trabalhamos{' '}
                <span>em parceria com as escolas e equipes terapêuticas.</span>
              </p>
            </div>
            <ImageFlip className="image-container" images={carousel2} />
          </div>
        </div>
      </div>
      <div className="section-container" id="especialidades">
        <div className="content-container">
          <div className="especialidades-container">
            <div className="text-area">
              <p className="section-name">Especialidades</p>
              <h2 className="m-b-64 ">
                Realizamos avaliação, acompanhamento, orientação e terapia
                fonoaudiológica nas seguintes áreas:
              </h2>
              <div className="cards-container">
                <div className="card-line">
                  <FonoCard
                    title="FALA"
                    items={[
                      'Transtorno dos sons da fala',
                      'Atraso motor de fala',
                      'Apraxia de fala na infância',
                      'Gagueira',
                    ]}
                    icon={<Fala />}
                  />
                  <FonoCard
                    title="LINGUAGEM ORAL"
                    items={[
                      'Atraso de linguagem',
                      'TDL (Transtorno de desenvolvimento da linguagem',
                      'TL (Transtornos de linguagem)',
                    ]}
                    icon={<LinguagemOral />}
                  />
                </div>

                <div className="card-line">
                  <FonoCard
                    title="LINGUAGEM ESCRITA"
                    items={[
                      'Dificuldades escolares',
                      'Transtorno específico de aprendizagem (como Dislexia)',
                      'Alterações de aprendizagem (TDAH, deficiência intelectual, alteração no processamento auditivo)',
                    ]}
                    icon={<LinguagemEscrita />}
                  />
                  <FonoCard
                    title="MOTRICIDADE OROFACIAL"
                    items={[
                      'Crianças em acompanhamento ortodôntico',
                      'Respiração oral',
                      'Dificuldades de alimentação',
                    ]}
                    icon={<MotricidadeOrofacial />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Team />
      <Gallery images={gallery} className="section-container" id="galeria" />
      <GetToKnowUs
        className="section-container background-3"
        id="venhaconhecer"
      />
      <div className="section-container" id="informacoes">
        <div className="content-container">
          <div className="info-container">
            <div className="text-area">
              <p className="section-name">Informações</p>
              <h3>FONÍ - Clínica Infantil</h3>
            </div>
            <div className="infos-flex">
              <div className="contato">
                <p>
                  <span>Contato:</span>
                </p>
                <p>Telefone / WhatsApp: (11) 93284-9257 </p>
                <p>E-mail: foniclinicainfantil@gmail.com </p>
                <p>
                  Instagram:{' '}
                  <a
                    href="https://www.instagram.com/foniclinicainfantil/"
                    target="_blank"
                  >
                    @foniclinicainfantil
                  </a>
                </p>
              </div>
              <div className="contato">
                <p>
                  <span>Horário de Funcionamento:</span>
                </p>
                <p>Segunda à Sexta: das 8h00 às 20h00</p>
              </div>
              <div className="contato">
                <p>
                  <span>Endereço:</span>
                </p>
                <p>
                  Rua Bom Pastor, 2224 - conjuntos 1210-1305 - Ipiranga, São
                  Paulo- SP, 04203-002
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="section-container background-2" id="siteemconstrucao">
        <h1>
          Site em construção... <br /> mais informações em breve!
        </h1>
      </div> */}
      {/* <div className="section-container">
      </div> */}
      <Footer />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomePics {
    somos1: file(relativePath: {eq: "somos-foni-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
    somos2: file(relativePath: {eq: "somos-foni-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
    comunicacao1: file(relativePath: {eq: "comunicacao1.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
    comunicacao2: file(relativePath: {eq: "comunicacao2.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
      }
    }
    galeria: file(relativePath: {eq: "espaco1.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria1: file(relativePath: {eq: "espaco2.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria2: file(relativePath: {eq: "espaco3.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria3: file(relativePath: {eq: "espaco4.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria4: file(relativePath: {eq: "espaco5.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria5: file(relativePath: {eq: "novo-espaco-1.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria6: file(relativePath: {eq: "novo-espaco-2.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
    galeria7: file(relativePath: {eq: "novo-espaco-3-cut.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP])
      }
    }
  }
`;
