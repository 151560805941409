import {useEffect, useState} from 'react';

const useTabFocus = (): boolean => {
  const [inTab, setInTab] = useState(false);

  const onBlur = () => {
    setInTab(false);
  };
  const onFocus = () => {
    setInTab(true);
  };

  useEffect(() => {
    if (document.hasFocus()) onFocus();
    if (onFocus) window.addEventListener('focus', onFocus);
    if (onBlur) window.addEventListener('blur', onBlur);
    return () => {
      if (onFocus) window.removeEventListener('focus', onFocus);
      if (onBlur) window.removeEventListener('blur', onBlur);
    };
  }, []);

  return inTab;
};

export default useTabFocus;
