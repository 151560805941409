import React, {FC} from 'react';
import {GatsbyImage} from 'gatsby-plugin-image';

import styles from 'styles/TeamCard.module.scss';

interface Props {
  photo: object;
  name: string;
  crfa: string;
  graduation: string[];
  acting: string[];
}

const TeamCard: FC<Props> = ({photo, name, crfa, graduation, acting}) => {
  return (
    <div className={styles.container}>
      {/* <div className={styles.imageArea}> */}
      <GatsbyImage
        image={photo}
        alt={`Foto de perfil de ${name}`}
        className={styles['image-container']}
        layout="constrained"
        objectFit="cover"
        // width={500}
        // height={500}
      />

      <div className={styles['text-area']}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.crfa}>CRFa {crfa}</p>
        <p className={styles.title}>
          <span>Formação</span>
        </p>
        <ul className={styles.list}>
          {graduation.map(text => (
            <li className="m-t-6 m-b-6" key={text}>
              {text}
            </li>
          ))}
        </ul>
        <p className={styles.title}>
          <span>Atuação</span>
        </p>
        <ul className={styles.list}>
          {acting.map(text => (
            <li className="m-t-6 m-b-6" key={text}>
              {text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TeamCard;
