import clsx from 'clsx';
import React, {FC, useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {useInView} from 'react-intersection-observer';

import useTabFocus from 'hooks/useTabFocus';

import styles from 'styles/ImageFlip.module.scss';

const variants = {
  enter: {opacity: 0},
  center: {opacity: 1},
  exit: {opacity: 0},
};

interface Props {
  className?: string;
  images: (IGatsbyImageData | undefined)[];
}

const ImageFlip: FC<Props> = ({className, images}) => {
  const {ref, inView} = useInView({threshold: 0.1});
  const inTab = useTabFocus();
  const [page, setPage] = useState(0);

  const imageIndex = page % images.length;
  const currImg = images[imageIndex];

  useEffect(() => {
    if (inTab && inView) {
      const interval = setInterval(() => setPage(prev => prev + 1), 3000);
      return () => clearInterval(interval);
    }
  }, [inTab, inView]);

  return (
    <div ref={ref} className={clsx(className, styles['image-flip'])}>
      <AnimatePresence initial={false}>
        <motion.div
          key={page}
          className={styles['animation-div']}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{opacity: {type: 'tween', duration: 1.7}}}
          drag="x"
          dragConstraints={{left: 0, right: 0}}
          dragElastic={1}
        >
          {!!currImg && (
            <GatsbyImage className={styles.image} alt="" image={currImg} />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ImageFlip;
