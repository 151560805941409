import React, {FC} from 'react';

const Footer: FC = () => {
  return (
    <div className="footer">
      <a href="https://nanten.dev" target="_blank">
        <span> @nanten.dev</span>
      </a>
    </div>
  );
};

export default Footer;
