import React, {FC} from 'react';
import clsx from 'clsx';

import styles from 'styles/ButtonLink.module.scss';

interface Props {
  href: string;
  variation?: boolean;
}

const ButtonLink: FC<Props> = ({children, href, variation = false}) => {
  return (
    <a
      href={href}
      className={clsx(styles['simple-button'], variation && styles.variation)}
      tabIndex={0}
      target="_blank"
    >
      {children}
    </a>
  );
};

export default ButtonLink;
